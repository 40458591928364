import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import BrowseData from '../../BrowseData';
import useGetActivityLog from '../../../hooks/useGetActivityLog';
import {
    browseActivitiesId,
    browseDataContext,
    browseDataContextSingular,
    searchPlaceholder,
    sortOptions,
} from './constants';
import { LOADING, ERROR } from '../../../config/constants';


const BrowseActivity = memo(() => {
    const { resourceId, resourceType } = useParams();
    const { activityLog, isLoading, error } = useGetActivityLog(resourceId, resourceType);

    if (isLoading) return <div className='loading-page'>{LOADING}...</div>;
    if (error) return <div className='error-page'>{ERROR}: {error}</div>;

    return (
        <BrowseData
            id={browseActivitiesId}
            context={browseDataContext}
            contextSingular={browseDataContextSingular}
            dataSet={activityLog}
            searchPlaceholder={searchPlaceholder}
            searchFilterAttribute={''}
            sortOptions={sortOptions}
        />
    );
});

BrowseActivity.displayName = 'BrowseActivity';

BrowseActivity.propTypes = {
    resourceId: PropTypes.string,
    resourceType: PropTypes.string,
};

export default BrowseActivity;
