import Toast from '../../components/Alerts/Toast/Toast';
import ToastConstants from '../../components/Alerts/Toast/constants';
import projectManagementAPIs from '../../services/project-management.service';
import { downloadDocumentsLimit } from '../../config/constants';

const flatLotResponses = (submissionResponses) => submissionResponses
    .flatMap((submission) => submission.responses);

const flatSubmissions = (submissions) => submissions
    .flatMap((submission) => (submission.lotID
        ? flatLotResponses(submission.responses) : submission.responses));

const getResponseIDsWithDocs = async (responsesID) => {
    try {
        const response = await projectManagementAPIs
            .getAllDocumentsByResponseIDs(responsesID);
        if (response.status === 200) {
            return response.data;
        }
        Toast.fire({
            icon: ToastConstants.error,
            titleText: ToastConstants.unableToRetrieveInfo,
        });
    } catch (error) {
        Toast.fire({
            icon: ToastConstants.error,
            titleText: ToastConstants.unableToRetrieveInfo,
        });
    }
    return null;
};

const getResponseDocuments = (response) => response.supportingAnswerDocuments.map((doc) => doc);

const getLotsResponseDocuments = (response, docList) => {
    const list = { ...docList };
    const lotsResponseDocs = {};
    response.lots
        .forEach((lot) => {
            if (list.withLots[lot.lotTitle]) {
                lotsResponseDocs[lot.lotTitle] = [
                    ...list.withLots[lot.lotTitle],
                    ...getResponseDocuments(response),
                ];
                lotsResponseDocs[lot.lotTitle] = [...new Set(lotsResponseDocs[lot.lotTitle])];
            } else {
                lotsResponseDocs[lot.lotTitle] = getResponseDocuments(response);
            }
        });
    return lotsResponseDocs;
};

const getDocListStructure = (response, docList) => {
    const docListClone = { ...docList };
    if (response.lots.length) {
        const lotsResponseDocs = getLotsResponseDocuments(response, docListClone);

        docListClone.withLots = {
            ...docListClone.withLots,
            ...lotsResponseDocs,
        };
    } else {
        const docs = getResponseDocuments(response);
        docListClone.noLots = [
            ...docListClone.noLots,
            ...docs,
        ];
    }
    docListClone.supplierName = response.supplier.companyName;
    return { ...docListClone };
};

const getSuppliersWithDocsFromResponses = (responses) => responses
    .reduce((accBySupplier, currentResponse) => {
        const group = accBySupplier
            .find((item) => item.supplierAccountID === currentResponse.supplierAccountID);
        if (group) {
            const docs = group.supportingAnswerDocuments
                .concat(...currentResponse.supportingAnswerDocuments);
            group.supportingAnswerDocuments = [...new Set(docs)];
        } else {
            const supplierName = currentResponse.supplier.companyName.replace(/\./g, ' ');
            accBySupplier.push({
                supplierAccountID: currentResponse.supplierAccountID,
                lots: currentResponse.lots,
                supportingAnswerDocuments: [...currentResponse.supportingAnswerDocuments],
                supplier: { companyName: supplierName },
            });
        }
        return accBySupplier;
    }, []);

const getDocumentsIds = async (responses) => {
    const newDocList = {
        supplierName: '',
        withLots: {},
        noLots: [],
    };
    const responseIDs = [...new Set(responses.map((response) => response.responseID))];
    if (!responseIDs || responseIDs.length === 0) {
        return newDocList;
    }
    const responseIDWithDocsList = await getResponseIDsWithDocs(responseIDs);
    const responsesWithDocs = responseIDWithDocsList?.map((responseIDWithDocs) => {
        const flatReponse = responses
            .find((resp) => responseIDWithDocs.responseID === resp.responseID);
        return { ...flatReponse, ...responseIDWithDocs };
    });

    const totalDocs = responsesWithDocs?.flatMap((response) => response.supportingAnswerDocuments);
    let newDocListArray = [];
    if (!totalDocs) {
        return newDocListArray;
    }
    if (totalDocs?.length > downloadDocumentsLimit) {
        const supplierWithDocs = getSuppliersWithDocsFromResponses(responsesWithDocs);
        newDocListArray = supplierWithDocs
            .map((supplierDocs) => getDocListStructure(supplierDocs, newDocList));
    } else {
        newDocListArray.push(
            responsesWithDocs?.reduce((acc, response) => {
                const list = getDocListStructure(response, acc);
                return {
                    ...acc,
                    ...list,
                };
            }, newDocList),
        );
    }
    return newDocListArray;
};

export {
    getDocumentsIds,
    flatSubmissions,
};